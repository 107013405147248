@use "../../css/config/variables" as *;

.card-list {
  --local-flex-flow: row wrap;
  --local-justify: center;
  --local-align: stretch;
  padding: $size-small 0;

  &--table {
    --local-flex-flow: column nowrap;
    --card-size: 100%;
  }

  &--legacy {
    --card-size: 180px;
  }

  &--feature {
    --card-size: #{$width-feature};
  }

  &--mobile {
    --card-size: #{$width-mobile};
  }

  &--phone {
    --card-size: #{$width-phone};
  }

  &--tablet {
    --card-size: #{$width-tablet};
  }

  &__items {
    display: flex;
    flex-flow: var(--local-flex-flow);
    justify-content: var(--local-justify);
    align-items: var(--local-align);
    gap: 0.5em;

    & > * {
      flex: 0 1 var(--card-size);
    }
  }

  &__item {
    &--no-results {
      --card-size: $width-phone;

      text-align: center;
      padding: $size-small 0;
      margin: 0 auto;
    }
  }
}
