@use "../../css/config/variables.scss" as *;

.paginator {
  &__count {
  }

  &__pages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 1em;
    list-style: none;
  }

  &__page {
    display: grid;
    min-width: 44px;
    min-height: 44px;

    &--current {
      position: relative;
      flex: 0 1 var(--local-width);

      &:focus-within .paginator__submit {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__link {
    padding: $size-small;
  }

  &__input {
    text-align: center;
  }

  &__submit {
    position: absolute;
    top: calc(100% + 0.5em);
    left: 0;
    z-index: 1;
    visibility: hidden;
    opacity: 0;

    transition-property: visibility, opacity;
    transition-duration: var(--duration-global);
  }

  &__controller {
    display: none;
  }
}
