/*
  TODO: Spread the styles around page/component/block files.
*/

.flash_messages {
  background-color: #3a3d43;
  padding: 10px;
  text-align: center;
}

.subtitle {
  color: #737373;
}

.no-posts {
  text-align: center;
}

.upload-button {
  padding: 30px;
  margin-top: 5px;
  border-radius: 0.25rem;
  background: #3a3d43;
  font-size: 24px;
  text-align: center;
}

.activity-view {
  padding: 5px;
  margin: 0.5rem;
  border-radius: 0.25rem;
  background: #3a3d43;
  display: flex;
}

.activity-view-avatar {
  border-radius: 0.25rem;
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
}

.activity-view-p {
  margin-left: 5px;
}

.activity-view-name {
  font-weight: bold;
  font-size: 20px;
}

.activity-view-update {
  display: block;
}

.jumbo {
  padding: 10px;
  margin: 0.5rem;
  border-radius: 0.25rem;
  background: hsl(220, 7%, 25%);

  & p,
  & h2 {
    margin: 0;
  }
}

.jumbo-user {
  text-align: center;
}

.jumbo-user-avatar {
  margin: 0 auto;
  border-radius: 0.25rem;
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
}

.favorites-opts select {
  margin-left: 0.5rem;
}

.opts {
  float: right;
  position: relative;
}

.opts select {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.visually-hidden {
  position: absolute;
  left: -100vw;
}

.hidden {
  display: none !important;
}

.text-card {
  height: 500px;
  background: #3a3d43;
  border-radius: 2px;
  margin: 0.5rem;
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow-y: auto;
}

.card-image {
  overflow: hidden;
  position: relative;
  max-height: 310px;
}

.card-reveal {
  border-radius: 2px;
  position: absolute;
  background-color: #3a3d43;
  width: 100%;
  overflow-y: auto;
  top: 0;
  height: 100%;
  z-index: 1;
  display: none;
}

.card-reveal-content {
  padding: 20px;
}

.card-reveal-content img {
  max-width: 100%;
}

.card-content {
  overflow: hidden;
  padding: 20px;
  border-radius: 0 0 2px 2px;
  height: 170px;
}

.card-title {
  font-size: 24px;
  font-weight: 300;
}

.card-action {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid rgba(160, 160, 160, 0.2);
  padding: 20px;
}

.card-action a {
  margin-right: 10px;
}

.card-image img {
  border-radius: 2px 2px 0 0;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

/* thumbnails */
.thumb-standard {
  border: 1px solid #000;
}
.thumb-child {
  border: 1px solid #cc0;
}
.thumb-parent {
  border: 1px solid #0f0;
}
.thumb-shared {
  border: 1px solid #ff7f00;
}

.thumb-link {
  margin: 2px;
}

.thumb {
  width: 200px;
  height: 200px;
}

.thumb:hover .thumb-with-image-overlay {
  display: block;
}

.thumb-with-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("#assets/loading.gif");
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.thumb-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.thumb-with-image-overlay {
  display: none;
  z-index: 9;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  text-align: center;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.thumb-with-text {
  overflow-y: auto;
  text-align: center;
}

.thumb-with-text * {
  padding: 2px;
}

.thumb * {
  margin: auto;
}

.thumb h3,
.thumb p {
  color: #fff;
}

/* sidebar */

.sidebar {
  float: left;
  margin-right: 5px;
  margin-left: 5px;
  width: 195px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  word-break: break-word;
}

.search-input {
  text-align: center;
}

/* search */

.results li {
  display: block;
  list-style-type: none;
  line-height: 1.8em;
}

.page img {
  max-width: 100%;
}

/* pagination */
.paginator {
  text-align: center;
}

.paginator menu {
  padding: 0;
  margin: 5px auto;
  display: table;

  & > li,
  & > a {
    border: 1px solid var(--colour0-tertirary);
    display: table-cell;
    line-height: 33px;
    color: var(--colour0-secondary);
    user-select: none;
    cursor: pointer;
    padding: 0;
    min-width: 35px;
    transition-property: color background-color;

    @media (min-width: #{600px + 1}) {
      &.pagination-mobile:not(:last-child) {
        display: none;
      }

      &.pagination-mobile:last-child {
        min-width: unset;
        border-right: none;
        border-top: none;
        border-bottom: none;
        & > * {
          display: none;
        }
      }
    }

    @media (max-width: 600px) {
      &.pagination-button-optional {
        display: none;
      }
      &.pagination-desktop {
        display: none;
      }
    }

    &.pagination-button-disabled {
      color: var(--colour0-tertirary);
      background-color: unset;
      cursor: default;
    }
    &.pagination-button-current {
      background-color: var(--anchour-internal-colour2-primary);
      color: var(--anchour-internal-colour1-secondary);
      border-color: var(--anchour-internal-colour1-primary);
    }
    &.pagination-button-after-current {
      border-left: 1px solid var(--anchour-internal-colour1-primary);
    }
    &:not(.pagination-button-disabled):hover,
    &:not(.pagination-button-disabled):focus,
    &:not(.pagination-button-disabled):active {
      background-color: var(--colour0-tertirary);
      color: var(--colour0-primary);
    }
    & > b {
      padding: 0 9px;
    }
    &:not(:last-child) {
      border-right: none;
    }
  }
}

menu li {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0 0.2em;
}

/* posts */

.embed-view {
  border: 1px solid #111;
  padding: 2px;
}

/* media queries */
@media only screen and (max-width: 568px) {
  .thumb {
    width: 160px;
    height: 160px;
  }
  .sidebar {
    width: auto;
  }
  #paginator-bottom {
    margin-bottom: env(safe-area-inset-bottom);
  }
}

/* search forms */

.search-form {
  display: table;
  padding: 0.5rem;
  margin-left: 5px;
  background-color: #282a2e;
  margin: 0px auto 8px auto;

  &-hidden {
    display: none;
  }

  & > div {
    display: table-row;
    line-height: 1.5em;
    margin-bottom: 2em;
  }

  & small {
    display: block;
    line-height: normal;
  }

  & label,
  & input {
    display: table-cell;
    padding-right: 1em;
    white-space: nowrap;
    text-align: left;
  }

  & label {
    text-align: right;
    font-weight: 700;
  }
}

/* search results */
.search-results tbody td {
  height: 2.25em;
  padding-right: 10px;
}

thead th {
  font-weight: 700;
  text-align: left;
  padding-right: 8px;
}

.user-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 0.25rem;
  overflow: hidden;

  // quick hack to apply styles

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ad-container {
  text-align: center;
}

.ad-container * {
  max-width: 100%;
}
