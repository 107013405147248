#popular-posts-paginator {
  display: grid;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;

  #daily, #weekly, #monthly {
    grid-row: 1;
  }
}
