@use "../css/config/variables" as *;

.tabs {
  margin: 0 auto;
  padding-bottom: 0.25em;
  padding-left: 0;
  list-style-type: none;

  & .tab {
    display: inline-block;
    line-height: 48px;
    height: 48px;
    padding: 0 0.75rem;

    & a {
      display: inline-block;
      height: 100%;

      &.active {
        border-bottom-color: var(--local-colour1-primary);
        border-bottom-width: 2px;
      }
    }
  }
}

.site-section--user {
  .no-results {
    --card-size: #{$width-phone};
    width: var(--card-size);
    padding: $size-small 0;
    margin: 0 auto;
  }
}

.user-header {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 720px;
  background-color: hsla(0, 0%, 0%, 0.7);
  border-radius: 10px;
  margin: 0 auto;
  overflow: hidden;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__avatar {
    flex: 0 0 10em;
    height: 10em;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    color: hsl(0, 0%, 100%);
    padding: 1em;
    padding-left: 2em;
  }

  &__name {
    margin: 0;
    margin-bottom: 0.5em;
  }

  &__profile {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    & span {
      font-weight: 700;
    }

    &-image {
      flex: 0 0 1.75em;
      padding-right: 0.5em;

      & img {
      }
    }
  }

  &__actions {
    font-size: 1.5em;
    font-weight: bold;
    text-shadow:
      hsl(0, 0%, 0%) 0px 0px 3px,
      hsl(0, 0%, 0%) -1px -1px 0px,
      hsl(0, 0%, 0%) 1px 1px 0px;
    color: hsl(0, 0%, 100%);
    border: transparent;

    & > * {
      margin-right: 1em;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__favourite {
    box-sizing: border-box;
    font-weight: bold;
    color: hsl(0, 0%, 100%);
    text-shadow:
      hsl(0, 0%, 0%) 0px 0px 3px,
      hsl(0, 0%, 0%) -1px -1px 0px,
      hsl(0, 0%, 0%) 1px 1px 0px;
    background-color: transparent;
    border: transparent;
    user-select: none;

    &--unfav {
      color: hsl(51, 100%, 50%);

      // hack to overwrite * selector color
      & span {
        color: hsl(51, 100%, 50%);
      }
    }

    &--loading {
      cursor: progress;

      & .user-header__fav-icon {
        display: none;
      }
    }
  }

  @media (max-width: $width-phone) {
    flex-flow: column nowrap;
    align-items: center;

    &__info {
      padding-left: 1em;
    }
  }
}

// TODO: check how user blocking works
// .user-header-blocked {
//   box-sizing: border-box;
//   font-size: 32px;
//   font-weight: bold;
//   color: hsl(0, 0%, 100%);
//   text-shadow:
//   rgb(0, 0, 0) 0px 0px 3px,
//   rgb(0, 0, 0) -1px -1px 0px,
//   rgb(0, 0, 0) 1px 1px 0px;
//   cursor: pointer;
//   user-select: none;
// }
