$kemono-site: 'https://kemono.su';
.messages {
  flex: 1 1 0;

  display: flex;
  flex-flow: column nowrap;
  gap: 1em;
  list-style-type: none;
  padding: 0;

  & p {
    line-height: normal;
  }
}

.message {
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);

  & a {
    text-decoration: underline;
    color: #81a2be;
    font-family: Helvetica;
  }
}

.avatar {
  grid-area: avatar;
  min-width: 32px;
  max-height: 32px;
}

.header {
  display: flex;
  gap: 1em;
  align-items: center;
}

.username {
  margin: 0;
  margin-right: 8px;
}

.body {
  white-space: pre-line;
  margin: 0.5em 0;
}

.image {
  max-width: 300px;
}

.embed {
  display: block;
  max-width: 300px;
}

.emoji {
  max-width: 20px;
  max-height: 20px;
}

.published {
  color: #757575;
}
