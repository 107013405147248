$kemono-site: 'https://kemono.su';
.block {
  display: grid;
  // TODO: $size-little
  gap: 0.25em;
}

.section {
  // TODO: $radius-normal
  border-radius: 10px;

  &_keyValue {
    display: inline-grid;
    grid:
      "key" auto
      "value" auto
      / 100%;
    justify-content: flex-start;
    align-items: center;
    // TODO: $size-little
    gap: 0.25em;
  }

  &_horizontal {
    grid:
      "key value" auto
      / auto;
    // TODO: $size-normal
    gap: 1em;
  }
}

.key {
  grid-area: key;
}

.value {
  grid-area: value;
  word-break: break-word;
}

.term {
  font-weight: bold;
}

.definition {
}
