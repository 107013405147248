@use "../../css/config/variables" as *;

.site-section {
  margin: 0 auto;

  &__header {
    padding: 0 0 $size-little;
  }

  &__heading {
    font-weight: 300;
    text-align: center;
    margin: 0;
  }

  &__subheading {
    margin: 0.5em 0;
  }

  &__register-cta {
    text-align: center;
  }
}
