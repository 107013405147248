.importer-state-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 0;

  & > :last-child {
    margin-left: 1em;
  }
}
