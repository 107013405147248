$kemono-site: 'https://kemono.su';
.channels {
  display: flex;
  flex-flow: column nowrap;
  gap: 1em;
  list-style-type: none;
  width: 15vw;
  min-width: 215px;
  max-width: 300px;
  background-color: #282a2e;
  padding: 0;
}

.channel {
  display: flex;
  padding: 10px 12px;
  border-bottom: 1px solid #1d1f21;
}

.active {
  background-color: #1d1f21;
}
