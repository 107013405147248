@use "../../../css/config/variables" as *;

.site-section--moderator-dashboard {
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
}

section#card-list {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  gap: 10px;

  article.link-request-card {
    width: 310px;
    background-color: #282a2e;
    padding: 0.5em;
    border-radius: 5px;

    h6 {
      color: var(--colour0-secondary);
    }

    .control {
      text-align: center;
      padding-top: 0.5em;

      button {
        border: none;
        background-color: var(--colour1-primary);
        font-size: 150%;
        width: calc((310px - 2em) / 2);
        height: 36px;

        &.approve {
          color: green;
        }

        &.reject {
          color: red;
        }
      }
    }
  }
}
