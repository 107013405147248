div#add-new-link {
  text-align: center;
}

section.site-section--new-linked-account {
  p.link-notice {
    text-align: center;
  }

  .card-list .user-card.selected {
    border: 2px solid green;
  }

  .user-card__service {
    width: fit-content;
  }
}

#new_link_form {
  div#artist-section {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;

    span {
      display: flex;
      flex-direction: column;

      &:nth-child(1) {
        margin-left: auto;
      }

      &:nth-child(2) {
        margin-left: 1em;
        margin-right: auto;
      }
    }
  }

  div#reason-section {
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;

    #reason {
      width: 100%;

      &.invalid {
        border: 2px solid red;
      }
    }
  }

  #button-section {
    text-align: center;

    button {
      max-width: 360px;
    }
  }
}

button.remove-link {
  color: red;
  position: absolute;
  right: 1em;
  bottom: 1em;
}
