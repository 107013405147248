@use "config/variables" as *;

html {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  font-size: 100%;
  font-family: Helvetica, sans-serif;
  padding: 0;
  margin: 0;
  overflow-wrap: break-word;
  overflow: auto;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
}

body {
  height: 100%;
  width: 100%;
  color: var(--colour0-primary);
  background-color: var(--colour1-primary);
  padding: 0;
  margin: 0;
}

div[id="root"] {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

main {
}

h1 {
  text-transform: capitalize;
  font-size: 1.7rem;
  font-weight: normal;
  margin: 0;
}

h2 {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.35;
  margin: 0;
}

h3 {
  font-size: 1.5rem;
  font-weight: normal;
  margin: 0;
}

h4 {
  font-size: 1.4rem;
  font-weight: normal;
  margin: 0;
}

h5 {
  font-size: 1.3rem;
  font-weight: normal;
  margin: 0;
}

h6 {
  font-size: 1.2rem;
  font-weight: normal;
  margin: 0;
}

p,
li,
dd,
dt {
  line-height: 1.5;
}

p,
ul,
ol {
  margin: $size-small 0;
}

ul,
ol {
  padding-left: $size-normal;
}

a {
  --local-colour1-primary: var(--anchour-colour1-primary);
  --local-colour1-secondary: var(--anchour-colour1-secondary);
  --local-colour2-primary: var(--anchour-colour2-primary);
  --local-colour2-secondary: var(--anchour-colour2-secondary);

  outline: none;
  text-decoration: none;
  border-bottom: $size-nano solid transparent;
  padding: 2px;
  transition-property: color, border-color, background-color;
  transition-duration: var(--duration-global);

  &:link {
    color: var(--local-colour1-primary);
  }

  &:visited {
    color: var(--local-colour1-secondary);
  }

  &:focus {
    background-color: var(--local-colour2-primary);
    border-bottom-color: var(--local-colour1-primary);
  }

  &:hover {
    background-color: var(--local-colour2-secondary);
    border-bottom-color: var(--local-colour1-primary);
  }

  &:active {
    background-color: var(--local-colour1-primary);
    color: var(--local-colour2-primary);
    border-bottom-color: var(--local-colour2-primary);
  }
}

img {
  max-width: 100%;
  height: auto;
}

button,
input,
select,
textarea,
.pure-g [class*="pure-u"] {
  font-family: Helvetica, sans-serif;
}

label {
  cursor: pointer;
}

textarea {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 3rem;
  box-shadow: inset 0 1px 3px #1f2024;
  background: #3a3d43;
  color: var(--colour0-primary);
  border: none;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

select {
  color: var(--colour0-primary);
  background-image: linear-gradient(#282a2e, #111111);
  border-radius: 5px;
  border-color: #111;
  text-align-last: center;
  cursor: pointer;
}

select * {
  color: #000;
}

select::-ms-expand {
  color: #000;
}

button {
  cursor: pointer;

  // prevents onclick events from firind on children
  & > * {
    pointer-events: none;
  }
}

*[hidden] {
  display: none !important;
}
