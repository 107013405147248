a.image-link {
  --local-colour1-primary: transparent;
  --local-colour1-secondary: transparent;
  --local-colour2-primary: transparent;
  --local-colour2-secondary: transparent;

  background-color: transparent;
  border: none;
  padding: 0;
  overflow: hidden;

  &:link {
    color: var(--local-colour1-primary);
  }

  &:visited {
    color: var(--local-colour1-secondary);
  }

  &:focus {
    background: var(--local-colour2-primary);
  }

  &:hover {
    background: var(--local-colour2-secondary);
  }

  &:active {
    background: var(--local-colour1-primary);
    color: var(--local-colour2-primary);
  }

  & .fancy-image__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
