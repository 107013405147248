@use "../../css/config/variables" as *;

.post-card {
  width: var(--card-size);
  height: var(--card-size);
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  color: white;
  font-size: 80%;

  &--fav {
    border-color: var(--favourite-colour2-primary);
    border-style: solid;
    border-width: 2px;
  }

  &--fav-profile {
    border-bottom-color: var(--favourite-colour2-secondary);
    border-bottom-style: solid;
    border-bottom-width: 2px;
  }

  &:hover {
    & > a {
      top: -5px;
    }
  }

  & > a:active,
  & > a:focus {
    top: -5px;
  }

  &--preview {
    .post-card__header {
      background: rgb(0 0 0 / 50%);
      &--fav {
        background: hsla(60, 100%, 30%, 0.5);
      }
    }

    .post-card__footer {
      background: rgb(0 0 0 / 50%);
      &--fav {
        background: hsla(60, 100%, 30%, 0.5);
      }
    }
  }

  & > a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0;
    position: relative;
    top: 0;
    transition: top ease 0.1s, background ease 0.1s,
      border-bottom-color ease 0.1s;

    &:not(:hover):not(:active):not(:focus) {
      background: black;
    }
  }

  &__header {
    padding: 5px;
    z-index: 1;
    color: white;
  }

  &__image-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__footer {
    padding: 5px;
    z-index: 1;

    & > div > img {
      height: 20px;
      margin-left: auto;
    }
    & > div > div > time {
      color: var(--colour0-primary);
    }
    & > div {
      color: white;
      display: flex;
      align-items: center;
    }
  }

  &__profile {
    color: white;
    border-color: transparent;
    border-style: solid;
    border-width: 2px;

    &--fav {
      border-color: var(--favourite-colour2-primary);
    }
  }
}
