@use "../../css/config/variables" as *;

.user-card {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.25rem;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  grid-gap: 1rem;
  gap: 1rem;
  border-radius: 10px;
  background-color: #282a2e;
  background-size: cover;
  transition: top ease 0.1s;
  top: 0;
  border-bottom: none;
  --local-colour1-primary: none;
  --local-colour1-secondary: none;
  --local-colour2-primary: none;
  --local-colour2-secondary: none;

  &--fav {
    border-color: var(--favourite-colour2-primary);
    border-style: solid;
    border-width: 2px;
  }

  &:hover,
  &:focus-within {
    top: -3px;
  }

  &__info {
    padding: 5px;
  }

  &__service {
    color: #fff;
    font-weight: 700;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.25rem;
    font-size: 14px;
  }

  &__icon {
    width: 80px;
    height: 80px;
    align-self: center;
    overflow: hidden;
    border-radius: 10px;

    background-image: url("/static/loading.gif");
    background-position: center;
    background-repeat: no-repeat;

    & img {
      object-fit: cover;
    }
  }

  &__name {
    font-weight: 300;
    font-size: 28px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #fff;
    max-width: 320px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__count {
    color: #fff;
  }
}
