.form__hint.invalid {
  color: red;
}

.form__hint {
  color: green;
}

button:disabled{
  color: hsl(0deg 0% 40%);
  background-image: linear-gradient(#4a5059, #4a5059);
  cursor: not-allowed;
}