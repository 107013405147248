@use "../../css/config/variables" as *;

.site-section--account-keys {
  .key {
    &__view {
      position: relative;
      padding: 0;
      margin-bottom: 3em;
    }

    &__revoke-check {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      max-width: 1px;

      &:checked + .key__info {
        --local-colour1: var(--colour1-tertiary);
        --local-background-colour1: var(--negative-colour1-primary);
        --local-border-colour1: var(--negative-colour1-primary);
      }
    }

    &__info {
      --local-colour1: var(--colour0-primary);
      --local-background-colour1: var(--colour1-tertiary);
      --local-border-colour1: var(--colour1-tertiary);

      max-width: var(--card-size);
      height: 100%;
      border-radius: 10px 10px 0 10px;
      border: $size-thin solid var(--local-border-colour1);
      overflow: hidden;
      transition-duration: var(--duration-global);
      transition-property: border-color;

      & .key__card {
        height: 100%;
        border-radius: 0;
      }
    }

    // &__card {}
    &__label {
      position: absolute;
      top: 100%;
      right: 0;
      color: var(--local-colour1);
      background-color: var(--local-background-colour1);
      border-radius: 0 0 10px 10px;
      border: $size-thin solid var(--local-border-colour1);
      border-top: none;
      padding: $size-small;
      transition-duration: var(--duration-global);
      transition-property: color, background-color, border-color;
    }
  }
}
