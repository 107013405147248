@use "../css/config/variables" as *;

.site-section--home {
}
// minheights

.jumbo-welcome {
  overflow-y: hidden;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
  min-height: 450px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: $size-small;

  @media (max-width: $width-tablet) {
    background-color: #3b3e44;
  }
}

.jumbo-welcome-mascot {
  transform: translateZ(0);
  display: flex;
  width: 100%;
  height: 100%;
  @media (max-width: $width-tablet) {
    display: none;
  }
}

.jumbo-welcome-description {
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0.5em 2em;
}

.jumbo-welcome-description-header {
  text-align: center;
}

.jumbo-welcome-credits {
  overflow: hidden;
  display: block;
  float: right;
  text-align: right;
}

.jumbo-welcome-background {
  z-index: -1;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
}

.date {
  //position: absolute; /* Add this line */
  //top: 10px; /* Adjust the top position as needed */
  //right: 10px; /* Adjust the right position as needed */
  color: #d5d5d5;
}

.announcement-title-container {
  display: flex;
  align-items: baseline;
  gap: 15px;
}
.announcement-text {
  color: #929292;
}

.jumbo-announcement {
  padding: 10px;
  margin: 0.5rem;
  border-radius: 0.25rem;
  background: hsl(220, 7%, 25%);

  & p,
  & h2 {
    margin: 0;
  }
}
