@use "../../css/config/variables" as *;

.site-section--account-notifications {
  .notifications {
    &__list {
      list-style: none;
      padding: 0;
    }

    &__item {
    }

    &__date {
    }

    &__message {
    }
  }
}
