$kemono-site: 'https://kemono.su';
.dropdowns {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
  justify-content: center;
}

.label {
  text-align: right;

  ::after {
    content: ":";
  }
}
