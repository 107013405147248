$kemono-site: 'https://kemono.su';
@use "../../css/config/variables" as *;

.block {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  background-color: var(--colour1-tertiary);
  border-radius: 10px;
  border: $size-thin solid transparent;
  overflow: hidden;
  transition-duration: var(--duration-global);
  transition-property: border-color, box-shadow;

  & > * {
    flex: 0 1 auto;
    padding: $size-small;
  }

  &:hover,
  &:focus-within {
    box-shadow: 0px 0px 3px 5px var(--positive-colour1-secondary);
  }
}

.body {
  flex: 1 1 auto;
}

.content {
  font-family: inherit;
  line-height: 1.5;
}
