@use "../css/config/variables" as *;

.post {
  &__nav-list {
    display: flex;
    height: 2rem;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0.5rem;
  }

  &__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    border: solid hsla(0, 0%, 50%, 0.7) $size-thin;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }

  &__user {
    position: relative;
    flex: 0 0 220px;
    text-align: center;
    background-color: hsla(0, 0%, 0%, 0.7);
    border-right: solid hsla(0, 0%, 50%, 0.7) $size-thin;
    padding: 0.5em;
  }

  &__user-profile {
    display: inline-block;
    width: 160px;
    height: 160px;
    border-radius: 10px;
    margin: 0 auto;
  }

  &__user-name {
    font-size: 1.25em;
    display: inline-block;
  }

  &__info {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background-color: var(--colour1-secondary);
    padding: 0.5em;
    padding-left: 2em;

    & > * {
      margin: 0.75rem 0;
    }
  }

  &__title {
  }

  &__published {
    color: var(--colour0-secondary);
  }

  &__added {
    color: var(--colour0-secondary);
  }

  &__edited {
    color: var(--colour0-secondary);
  }

  &__actions {
    font-size: 1.5em;

    & > * {
      margin-right: 1em;
      user-select: none;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__flag {
    display: inline-block;
    color: hsl(3, 100%, 69%);
    font-weight: bold;
    text-shadow:
      hsl(0, 0%, 0%) 0px 0px 3px,
      hsl(0, 0%, 0%) -1px -1px 0px,
      hsl(0, 0%, 0%) 1px 1px 0px;
    background-color: transparent;
    border: transparent;

    // hack to overwrite * selector color
    & span {
      color: hsl(3, 100%, 69%);
    }

    &--flagged {
      color: hsl(0, 0%, 45%);

      // hack to overwrite * selector color
      & span {
        color: hsl(0, 0%, 45%);
      }
    }

    &--loading {
      cursor: progress;

      & .post__flag-icon {
        display: none;
      }
    }
  }

  &__fav {
    color: hsl(0, 0%, 100%);
    font-weight: bold;
    text-shadow:
      hsl(0, 0%, 0%) 0px 0px 3px,
      hsl(0, 0%, 0%) -1px -1px 0px,
      hsl(0, 0%, 0%) 1px 1px 0px;
    background-color: transparent;
    border: transparent;

    &--unfav {
      color: var(--favourite-colour1-primary);

      // hack to overwrite * selector color
      & span {
        color: var(--favourite-colour1-primary);
      }
    }

    &--loading {
      cursor: progress;

      & .post__fav-icon {
        display: none;
      }
    }
  }

  &__body {
    border-left: solid hsl(0, 0%, 50%) $size-thin;
    border-right: solid hsl(0, 0%, 50%) $size-thin;
    padding: $size-small;
  }

  &__attachments {
    list-style: none;
    padding: $size-small;
    margin: 0;
  }

  &__attachment {
    padding: $size-little 0;
  }

  &__content {
    line-height: 1.5;

    & p {
      padding: $size-small 0;
    }
  }

  &__files {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  &__thumbnail {
    margin: 0.5em 0;

    figcaption {
      text-align: center;
      background-color: var(--colour1-secondary);
      margin-top: -4px;
    }
  }

  &__video {
    width: 60%;
    height: 60%;
  }

  &__footer {
    border: solid hsl(0, 0%, 50%) $size-thin;
    border-radius: 0 0 10px 10px;
    padding: $size-small;
  }

  &__comments {
    &--no-comments {
      text-align: center;
    }

    & > * {
      margin-bottom: $size-normal;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: $width-phone) {
    &__header {
      flex-flow: column nowrap;
      align-items: stretch;
    }

    &__info {
      padding-left: 0.5em;
    }

    &__user {
      border-right: none;
      border-bottom: solid hsla(0, 0%, 50%, 0.7) $size-thin;
    }
    &__video {
      width: 100%;
      height: 100%;
    }
  }
}

article#poll {
  border-radius: 10px;
  border: $size-thin solid var(--colour1-secondary);
  max-width: $width-phone;
  margin-bottom: $size-normal;

  @media only screen and (orientation: portrait) {
    margin-left: auto;
    margin-right: auto;
  }

  & > div#poll-summary {
    border-bottom: $size-thin solid var(--colour1-secondary);

    > h4,
    > h6 {
      padding: $size-small;
    }
  }

  & > ul {
    padding-left: unset;
    cursor: default;

    > li {
      list-style-type: none;
      border: $size-thin solid var(--colour1-secondary);
      margin: $size-normal;
      position: relative;
      display: grid;
      grid-template-areas: "a b";
      grid-template-columns: auto 5ch;

      .choice-text {
        padding-left: $size-small;
        overflow: hidden;
        grid-area: a;
      }

      .choice-votes {
        padding-right: $size-small;
        float: right;
        grid-area: b;
        text-align: right;
      }

      .choice-fill {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: var(--colour1-secondary);
        z-index: -2;
      }
    }
  }

  & > footer {
    padding: $size-nano;
    border-top: $size-thin solid var(--colour1-secondary);
    cursor: default;

    li {
      display: inline;
      color: var(--colour0-secondary);

      &#end {
        margin-left: -4px;
      }
    }

    span.sep::after {
      color: var(--colour0-secondary);
      content: " | ";
    }
  }
}

section#post-tags {
  display: flex;
  margin: 2px 0;

  span#label {
    width: 89px;
    display: inline-block;
    color: var(--colour0-secondary);
  }

  div {
    display: flex;
    max-width: 720px;
    overflow: hidden;
  }

  div.show-overflow {
    overflow: unset;
    flex-wrap: wrap;
  }

  > div > a {
    color: unset;
    background-color: var(--colour1-primary);
    border-radius: 5px;
    padding: 3px 5px;
    margin: $size-thin;
    white-space: nowrap;
    font-size: 10px;
  }

  #show-tag-overflow-button {
    max-height: 21px;
    --local-colour1-primary: var(--anchour-internal-colour1-primary);
    --local-colour1-secondary: var(--anchour-internal-colour1-secondary);
    --local-colour2-primary: var(--anchour-internal-colour2-primary);
    --local-colour2-secondary: var(--anchour-internal-colour2-secondary);
    font-size: 12px;
    vertical-align: middle;
    padding-top: 4px;
  }
}

.comment {
  border-radius: 10px;
  border: $size-thin solid var(--colour1-secondary);
  max-width: $width-phone;

  &:target {
    outline-color: var(--anchour-local-colour1-primary);
    outline-width: $size-thin;
    outline-style: dashed;
  }

  & > * {
    padding: $size-small;
  }

  &--user {
    background-color: var(--colour1-secondary);
    border: none;
  }

  &__header {
    border-bottom: $size-nano solid var(--colour1-secondary);

    .comment-revisions-dialog {
      display: none;

      > div {
        // overlay
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
          // container
          background-color: var(--colour1-primary);
          // padding: 30px;
          max-width: 720px;
          max-height: 100vh;
          border-radius: 4px;
          overflow-y: auto;
          box-sizing: border-box;

          section {
            padding: 30px;
            width: 100%;
            height: 100%;
          }

          header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > h2 {
              margin-top: 0;
              margin-bottom: 0;
              font-weight: 600;
              font-size: 1.25rem;
              line-height: 1.25;
              box-sizing: border-box;
            }

            > button {
              font-size: 0.875rem;
              padding: 0.5rem 1rem;
              background-color: var(--colour1-primary);
              color: var(--colour0-primary);
              border-radius: 0.25rem;
              border-style: none;
              border-width: 0;
              cursor: pointer;
              text-transform: none;
              overflow: visible;
              line-height: 1.15;
              margin: 0;
              will-change: transform;
              backface-visibility: hidden;
              transform: translateZ(0);
              transition: transform 0.25s ease-out;

              &:focus {
                outline: none;
              }

              &::before {
                content: "\2715"; // ✕
              }
            }
          }

          main {
            margin-top: 2rem;
            margin-bottom: 2rem;
            line-height: 1.5;
            color: white;

            article {

              span.timestamp {
                padding-right: 0.5em;
              }

              span.prose {
                span.removed {
                  text-decoration: line-through;
                  color: #f77b7b;
                }

                span.added {
                  color: #8be78b;
                }
              }
            }
          }
        }
      }
    }

    .comment-revisions-dialog.is-open {
      display: block;
    }
  }

  &__reply {
    // padding: $size-little 0;
  }

  &__message {
    line-height: 1.5;
    margin: 0;
  }

  &__profile {
  }

  &__icon {
    display: inline-block;
    border-radius: 5px;
    overflow: hidden;
    width: 1em;
    height: 1em;
  }

  &__name {
  }

  &__body {
  }

  &__footer {
    border-top: $size-nano solid var(--colour1-secondary);
  }
}
