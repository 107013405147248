@use "../../css/config/variables" as *;

h2#all-tags-header {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

section#tag-container {
  width: 80vw;
  margin-top: $size-normal;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  article {
    border-radius: 10px;
    background-color: var(--colour1-secondary);
    width: fit-content;
    display: inline-flex;
    margin: $size-thin;

    span:first-child {
      padding-right: $size-nano;
    }

    span:nth-child(2) {
      color: aqua;
      padding-left: $size-thin;
    }

    span {
      height: 100%;
      padding: $size-small;
    }

    a {
      color: unset;
      border-radius: 10px;
    }
  }
}
