/* SASS mixins go there */
@use "config/variables" as *;

@mixin article_card() {
  display: flex;
  flex-flow: column nowrap;
  border-radius: 10px;
  background-color: var(--colour1-tertiary);
  padding: 0;

  & > * {
    flex: 0 0 auto;
    padding: $size-small;
  }
}
