/* SASS variables */
// screen widths
$width-feature: 240px;
$width-mobile: 360px;
$width-phone: 480px;
$width-tablet: 720px;
$width-laptop: 1280px;
$width-desktop: 1920px;

// sizes for borders/padding/margins/gaps
$size-nano: 0.0625em;
$size-thin: 0.125em;
$size-little: 0.25em;
$size-small: 0.5em;
$size-normal: 1em;
$size-big: 2em;
$size-large: 3em;

// border radii
$radius-small: 5px;
$radius-normal: 10px;
$radius-big: 15px;
$radius-round: 50%;

// buttons
$button-min-width: 44px;
$button-min-height: 44px;

// min sidebar width do not touch
$sidebar-min-width: 1020px;

/* CSS variables */
:root {
  // base colours
  --colour0-primary: hsl(0, 0%, 95%);
  --colour0-secondary: hsl(0, 0%, 70%);
  --colour0-tertirary: hsl(0, 0%, 45%);
  // colors
  //--colour1-primary: hsl(200, 25%, 5%);
  //--colour1-primary-transparent: hsla(200, 25%, 5%, 0.75);
  //--colour1-secondary: hsl(208, 22%, 12%);
  //--colour1-secondary-transparent: hsla(208, 22%, 12%, 0.5);
  // kemono colors
  --colour1-primary: hsl(210, 6%, 12%);
  --colour1-primary-transparent: hsla(210, 6%, 12%, 0.75);
  --colour1-secondary: hsl(220, 7%, 25%);
  --colour1-secondary-transparent: hsla(220, 7%, 25%, 0.5);
  // coomer colors
  //--colour1-primary: hsl(200, 25%, 5%);
  //--colour1-primary-transparent: hsla(200, 25%, 5%, 0.75);
  //--colour1-secondary: hsl(208, 22%, 12%);
  //--colour1-secondary-transparent: hsla(208, 22%, 12%, 0.5);
  --colour1-tertiary: hsl(210, 15%, 5%);

  /* Buttons */
  --submit-colour1-primary: hsl(200, 100%, 70%);
  --submit-colour1-secondary: hsl(240, 100%, 50%);
  --submit-colour2-primary: hsl(240, 100%, 50%);
  --submit-colour2-secondary: hsl(240, 100%, 50%);
  --positive-colour1-primary: hsl(120, 100%, 45%);
  --positive-colour1-secondary: hsl(120, 100%, 30%);
  --negative-colour1-primary: hsl(0, 100%, 60%);
  --favourite-colour1-primary: hsl(51, 100%, 50%);
  --favourite-colour2-primary: hsl(60, 100%, 30%);
  --favourite-colour2-secondary: hsl(60, 100%, 20%);
  /* END Buttons */

  /* Links */
  // external
  --anchour-colour1-primary: hsl(200, 100%, 80%);
  --anchour-colour1-secondary: hsla(210, 70%, 70%);
  --anchour-colour2-primary: hsl(240, 100%, 40%);
  --anchour-colour2-secondary: hsla(230, 70%, 40%);
  // internal
  //--anchour-internal-colour1-primary: hsl(200, 100%, 80%);
  //--anchour-internal-colour1-secondary: hsla(210, 70%, 70%);
  //--anchour-internal-colour2-primary: hsl(240, 100%, 40%);
  //--anchour-internal-colour2-secondary: hsla(230, 70%, 40%);
  // kemono colors
  --anchour-internal-colour1-primary: hsl(20, 100%, 80%);
  --anchour-internal-colour1-secondary: hsla(20, 70%, 70%);
  --anchour-internal-colour2-primary: hsl(30, 100%, 20%);
  --anchour-internal-colour2-secondary: hsla(30, 80%, 20%);
  // coomer colors
  //--anchour-internal-colour1-primary: hsl(200, 100%, 80%);
  //--anchour-internal-colour1-secondary: hsla(210, 70%, 70%);
  //--anchour-internal-colour2-primary: hsl(240, 100%, 40%);
  //--anchour-internal-colour2-secondary: hsla(230, 70%, 40%);
  // local
  --anchour-local-colour1-primary: hsl(260, 100%, 80%);
  --anchour-local-colour1-secondary: hsla(260, 70%, 70%);
  --anchour-local-colour2-primary: hsl(280, 100%, 30%);
  --anchour-local-colour2-secondary: hsla(280, 70%, 30%);
  // email
  // --anchour-email-colour1-primary: hsl(260, 100%, 80%);
  // --anchour-email-colour1-secondary: hsla(260, 70%, 70%);
  // --anchour-email-colour2-primary: hsl(280, 100%, 30%);
  // --anchour-email-colour2-secondary: hsla(280, 70%, 30%);
  /* END Links */

  // durations
  --duration-fast: 250ms;
  --duration-global: var(--duration-fast);
}
