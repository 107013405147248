@use "../../css/config/variables.scss" as *;

.fancy-link {
  &--download {
  }
  &--kemono {
  }
  &--local {
  }

  &--button {
    display: grid;
    align-items: center;
    min-height: $button-min-height;
    min-width: $button-min-width;
    background-color: var(--local-colour2-secondary);
    border-radius: 5px;
    border: $size-nano solid var(--local-colour1-primary);
    padding: $size-small;

    & .fancy-link__text {
      background-color: transparent;
      border-bottom: $size-nano solid transparent;

      transition-property: color, border-color, background-color;
      transition-duration: var(--duration-global);
    }

    &:link {
      color: var(--local-colour1-primary);
    }

    &:visited {
      color: var(--local-colour1-primary);
    }

    &:focus {
      background-color: var(--local-colour2-primary);
    }

    &:hover {
      background-color: var(--local-colour2-primary);
      border-color: var(--local-colour1-primary);

      & .fancy-link__text {
        border-color: var(--local-colour1-primary);
      }
    }

    &:active {
      background-color: var(--local-colour1-primary);
      color: var(--local-colour2-primary);
      border-color: var(--local-colour2-primary);

      & .fancy-link__text {
        border-color: var(--local-colour2-primary);
      }
    }
  }

  &__text {
  }
}
