@use "../../css/config/variables" as *;

.card {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "body"
    "footer";
  background-color: var(--colour1-tertiary);
  border-radius: 10px;
  border: $size-thin solid transparent;
  overflow: hidden;
  transition-duration: var(--duration-global);
  transition-property: border-color, box-shadow;

  &:hover,
  &:focus-within {
    box-shadow: 0px 0px 3px 5px var(--positive-colour1-secondary);
  }

  & > * {
    padding: $size-small;
  }

  &__header {
    grid-area: header;
    align-self: flex-start;
  }

  &__body {
    grid-area: body;
    align-self: center;
  }

  &__footer {
    grid-area: footer;
    align-self: flex-end;
  }
}
