.fancy-image {
  &--background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;

    & .fancy-image__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // &____picture {}

  &__image {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
}
