div#fancard-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  margin-top: 0.5em;
  gap: 0.5em 0.25em;

  article.fancard__file {
    width: 400px;
    height: 293px;
    display: inline-grid;
    background-color: var(--colour1-secondary);

    span {
      padding-top: 0.5em;
      padding-left: 1em;
      display: inline-block;
      filter: unset;
    }

    img {
      padding: 1em;
      filter: unset;
      border-radius: 25px;
    }
  }
}
